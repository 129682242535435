<template>
  <div style="background-color: #fff;height:100%">
    <van-form style="padding-bottom: 60px" @submit="onSubmit(control.disabled)">
      <!-- <div class="content">
        <div class="page-view  page-content-view" style="margin:0.6rem;">
          <div style="width:100%;text-align: center;">
            <div>
              <van-image class="images" :src="show.headImg" round
                          style="height:2rem;width:2rem;border-radius:50%;border:1px solid #999999 !important; "/>
            </div>
          </div>
          <div v-if="!control.disabled" style="width:100%;text-align: center;">
            <div class="head-img-button" style="margin:0 auto;">
              <van-uploader :after-read="headImgButton">
                <van-button block plain type="primary" size="small" class="head-img-button">
                  上传照片
                </van-button>
              </van-uploader>
            </div>
          </div>
        </div>
      </div> -->
      <div class="border"></div>
      <van-cell hover-class="none">
        <div class="form-head">
          <div class="form-blue-view"></div>
          <div class="form-title-view">基本信息</div>
        </div>
      </van-cell>
      <van-field :readonly="control.disabled" label="照片" input-align="right" :center="true">
        <template #input>
          <van-uploader :after-read="headImgButton" v-model="show.headImg" :max-count="1" :disabled="control.disabled"></van-uploader>
        </template>

      </van-field>
      <van-field
          :readonly="control.disabled"
          v-model="dataForm.name"
          name="姓名"
          label="姓名"
          required
          placeholder="请输入姓名"
          :rules="[{ required: true }]"
          input-align="right"
      />
      <van-field
          readonly
          clickable
          required
          name="性别"
          :value="show.sex"
          label="性别"
          placeholder="请选择性别"
          :rules="[{ required: true }]"
          input-align="right"
          @click="showSex">
      </van-field>
      <van-popup v-model="control.sex" position="bottom">
        <van-picker
            show-toolbar
            value-key="label"
            :columns="[{value: 1, label:'男'}, {value: 2, label:'女'}]"
            @confirm="choseSex"
            @cancel="control.sex = false"
        />
      </van-popup>
      <van-field
          readonly
          clickable
          :value="show.nationalityStr"
          name="国籍"
          label="国籍"
          required
          placeholder="请选择国籍"
          @click="goNationality"
          input-align="right"
          :rules="[{ required: true }]"
      />
      <van-field
          readonly
          clickable
          :value="show.nationStr"
          name="民族"
          label="民族"
          required
          placeholder="请选择民族"
          @click="goNation"
          input-align="right"
          :rules="[{ required: true }]"
      />
      <van-field
          readonly
          clickable
          required
          name="出生日期"
          :value="dataForm.birthday"
          label="出生日期"
          placeholder="请选择出生日期"
          :rules="[{ required: true }]"
          @click="showBirthday"
          input-align="right"
      />
      <van-calendar v-model="control.birthday" :show-confirm="false" color="#1989fa" :default-date="new Date(1997,0,1)" :min-date="new Date(1945,0,1)" :max-date="new Date()" @confirm="birthdayCalendar"/>

      <div class="border"></div>
      <van-cell hover-class="none">
        <div class="form-head">
          <div class="form-blue-view"></div>
          <div class="form-title-view">户籍信息</div>
        </div>
      </van-cell>
      <van-field
          readonly
          clickable
          required
          name="户籍类型"
          :value="show.registryTypeStr"
          label="户籍类型"
          placeholder="请选择户籍类型"
          :rules="[{ required: true }]"
          input-align="right"
          @click="showRegistryType"
      />
      <van-popup v-model="control.registryType" position="bottom">
        <van-picker
            show-toolbar
            value-key="label"
            :columns="selectList.registryTypeList"
            @confirm="registryType"
            @cancel="control.registryType = false"
        />
      </van-popup>

      <van-field
          :readonly="control.disabled"
          v-model="dataForm.regAddress"
          name="户籍地址"
          label="户籍地址"
          placeholder="请输入户籍地址"
          input-align="right"
      />
      <van-field
          :readonly="control.disabled"
          v-model="dataForm.nowAddress"
          name="现居地"
          label="现居地"
          placeholder="请输入现居地址"
          input-align="right"
      />
      <van-field
          readonly
          clickable
          required
          name="证件类型"
          :value="show.idNumberTypeStr"
          label="证件类型"
          placeholder="点击选择证件类型"
          @click="showIdNumberType"
          input-align="right"
      />
      <van-popup v-model="control.idNumberType" position="bottom">
        <van-picker
            show-toolbar
            value-key="label"
            :columns="selectList.idNumberTypeList"
            @confirm="idNumberType"
            @cancel="control.idNumberType = false"
            input-align="right"
        />
      </van-popup>

      <van-field
          :readonly="control.disabled"
          v-model="dataForm.idNumber"
          name="有效证件号"
          label="有效证件号"
          required
          placeholder="请输入有效证件号"
          :rules="[{ required: true }]"
          input-align="right"
      />

      <div class="border"></div>
      <van-cell hover-class="none">
        <div class="form-head">
          <div class="form-blue-view"></div>
          <div class="form-title-view">附属信息</div>
        </div>
      </van-cell>
      <van-field
          readonly
          clickable
          name="标签管理"
          :value="show.labelStr"
          label="标签管理"
          placeholder="点击选择标签管理"
          @click="showlabel"
          input-align="right"
      />
      <van-popup v-model="control.label" position="bottom"
                  @click-overlay="labelOverlay">
        <van-cascader
            title="请选择"
            value="value"
            :field-names="{ text: 'label', value: 'value', children: 'children' }"
            :options="selectList.userCommunityLabelTree"
            active-color="#1989fa"
            @close="labelClose"
            @change="labelFinish"
        />
      </van-popup>

      <van-cell hover-class="none" v-if="labels.length>0">
        <template v-for="item in labels">
          <van-tag class="vanTag" type="primary" v-if="item.rightShow && !item.delete" :key="item.id"
                    :closeable="(!control.disabled) && item.rightUpdate" size="medium"
                    @close="removeLabel(item.value)">{{ item.name }}
          </van-tag>
        </template>

      </van-cell>
      <van-field
          :readonly="control.disabled"
          v-model="dataForm.mobile"
          name="手机号码"
          label="手机号码"
          required
          placeholder="请输入手机号码"
          :rules="[{ required: true }]"
          input-align="right"
      />
      <van-field
          :readonly="control.disabled"
          v-model="dataForm.company"
          name="工作单位"
          label="工作单位"
          placeholder="请输入工作单位"
          input-align="right"
      />
      <van-field
          :readonly="control.disabled"
          v-model="dataForm.emContact"
          name="紧急联系人"
          label="紧急联系人"
          placeholder="请输入紧急联系人"
          input-align="right"
      />
      <van-field
          :readonly="control.disabled"
          v-model="dataForm.emMobile"
          name="联系人手机号"
          label="联系人手机号"
          placeholder="请输入联系人手机号"
          input-align="right"
      />

      <van-field
          readonly
          clickable
          required
          name="是否死亡"
          :value="show.death"
          label="是否死亡"
          placeholder="请选择是否死亡"
          :rules="[{ required: true }]"
          input-align="right"
          @click="showDeath">
      </van-field>
      <van-popup v-model="control.death" position="bottom">
        <van-picker
            show-toolbar
            value-key="label"
            :columns="[{value: 1, label:'是'}, {value: 2, label:'否'}]"
            @confirm="choseDeath"
            @cancel="control.death = false"
        />
      </van-popup>
      <van-field
          v-if="dataForm.death==1"
          readonly
          clickable
          required
          name="死亡时间"
          :value="dataForm.deathDate"
          label="死亡时间"
          placeholder="点击选择死亡时间"
          @click="showDeathDate"
          input-align="right"
      />
      <van-calendar v-model="control.deathDate" :default-date="new Date()" :min-date="new Date(2000,0,1)" :max-date="new Date()" :show-confirm="false" color="#1989fa" @confirm="deathDateCalendar"/>

      <van-field
          :readonly="control.disabled"
          v-model="dataForm.resRemark"
          name="备注"
          label="备注"
          type="textarea"
          placeholder="请输入备注"
          input-align="right"
      />

      <div v-if="isRegAddress">
        <div class="border"></div>
        <van-cell hover-class="none">
          <div class="form-head">
            <div class="form-blue-view"></div>
            <div class="form-title-view">社保信息</div>
          </div>
        </van-cell>

        <van-field
            :readonly="true"
            v-model="dataForm.membership"
            name="城脑户籍"
            label="城脑户籍"
            placeholder="无"
            input-align="right"
        />
        <van-field
            :readonly="true"
            v-model="dataForm.nowAddress"
            name="居住房屋"
            label="居住房屋"
            placeholder="无"
            input-align="right"
        />
        <van-field
            :readonly="true"
            v-model="dataForm.moveOut"
            name="户籍变动状态"
            label="户籍变动状态"
            placeholder="无"
            input-align="right"
        />
        <van-field
            :readonly="true"
            v-model="dataForm.compareTime"
            name="排查时间"
            label="排查时间"
            placeholder="无"
            input-align="right"
        />
      </div>

    </van-form>

  </div>


</template>

<script>
import {mapState} from "vuex";
import {getImageStream} from "@/utils/index";
import {formatterDate, getBirthdayFromIdCard, getSexFromIdCard, identityCodeValid} from "@/utils/utils";
import {listComRightLabel, upload, getVirtualDict} from "@/utils/common"

var that
export default {
  name: "info",
  data() {
    return {
      isRegAddress: true,
      dataForm: {
        id: "",
        headImg: "",
        name: "",
        orgId: "",
        sex: "",
        nationality: 59,
        idNumberType: "1",
        idNumber: "",
        mobile: "",
        nation: 2,    //2是汉族
        birthday: "",
        company: "",
        regAddress: "",
        nowAddress: "",
        registryType: "",
        death: "0",
        deathDate: "",
        emContact: "",
        emMobile: "",
        resRemark: "",
        temporaryHeadImg: "",
        temporaryHeadImgSuffix: "",
        shorts: [],//简称连接
        labels: [],
        compareTime: '',
        membership: '',
        moveOut: ''
      },
      show: {
        sex: "",
        death: "否",
        idNumberTypeStr: "身份证",
        nationalityStr: "中国",
        nationStr: "汉族",
        headImg: [{url:getImageStream("files/wx/images/content/headImg.png")}],//展示头像图
        registryTypeStr: "",
        labelStr: '',
        membership: ''
      },
      control: {
        sex: false,
        death: false,
        disabled: false,
        idNumberType: false,
        birthday: false,
        deathDate: false,
        label: false,
        showNew: true,
        subareaPopup:false,
        houseTreeCascader:false,
        relationship:false,
        useType:false,
        psychosis:false,
        registryType:false
      },
      selectList: {
        idNumberTypeList: [],
        houseTree:[]
      },
      temporaryLabel: [],//临时标签
      labels: [],
      checkedHouses: [],//目前选中的房屋
    }
  },
  watch: {
    'dataForm.idNumber'(value, oldVal){
      if (that.dataForm.idNumberType == 1) {
        if (value.length == 18 || value.length == 15) {
          that.dataForm.birthday = getBirthdayFromIdCard(value);
          that.dataForm.sex = getSexFromIdCard(value);
        }
      }
    },
    'control.disabled': {
      immediate: true,
      handler (val) {
        if (val) {
          document.getElementsByClassName('van-uploader__preview-delete')[0].style.display = 'none'
        }
      }
    }
  },
  computed: {...mapState(['nationality', 'nation'])},
  created() {
    if (this.nationality && this.nationality.value) {
      this.dataForm.nationality = this.nationality.value
      this.show.nationalityStr = this.nationality.label
    }
    if (this.nation && this.nation.value) {
      this.dataForm.nation = this.nation.value
      this.show.nationStr = this.nation.label
    }
    that = this
  },
  methods: {
    init (options, isRegAddress) {
      this.isRegAddress = isRegAddress
      this.dataForm.id= parseInt(options.userId) || 0
      this.dataForm.name= options.name || ""
      this.dataForm.idNumberType= 1 || ""
      this.show.idNumberTypeStr= "身份证" || ""
      this.dataForm.idNumber= options.idNumber || ""
      this.dataForm.regAddress= options.regAddress || ""
      this.dataForm.sex= options.sex == "男" ? 1 : 2 || ""
      this.dataForm.nation= options.nation || "2"
      this.show.nationStr= options.nationStr || "汉族"
      this.userName= this.$globalData.userInfo.userName
      this.mobile= this.$globalData.userInfo.mobile
      this.userSelect()
      if (this.dataForm.id) {
        this.control.disabled=true
        if (isRegAddress) {
          this.dataFormInfo()
        } else {
          this.getInfo()
        }
      } else {
        this.dataForm.community=this.$globalData.userInfo.orgId
        this.dataForm.orgId=this.$globalData.userInfo.orgId
        //新建的，获取中国
      }
    },
    dataFormInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/app/social/domicile/info/${that.dataForm.id}/${that.$globalData.userInfo.orgId}`),
        method: 'post',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0) {
          // 用户基本信息
          that.dataForm.id = data.userInfo.id || 0
          that.dataForm.name = data.userInfo.name || ""
          that.dataForm.mobile = data.userInfo.mobile || ""
          that.dataForm.sex = data.userInfo.sex || ""
          that.dataForm.nationality = data.userInfo.nationality || ""
          that.dataForm.nation = data.userInfo.nation || ""
          that.dataForm.idNumber = data.userInfo.idNumber || ""
          that.dataForm.birthday = data.userInfo.birthday || ""
          that.dataForm.regAddress = data.userInfo.regAddress || ""
          that.dataForm.nowAddress = data.userInfo.nowAddress || ""
          that.dataForm.headImg = data.userInfo.headImg || ""
          that.dataForm.company = data.userInfo.company || ""
          that.dataForm.death = data.userInfo.death || "0"
          that.dataForm.deathDate = data.userInfo.deathDate || ""
          that.show.sex = data.userInfo.sex == 1 ? '男' : '女'
          that.show.nationStr = data.userInfo.nationStr || "未知"
          this.dataForm.membership=  data.domicile.membership || ""
          this.dataForm.compareTime=  data.domicile.compareTime || ""
          this.dataForm.moveOut=  data.domicile.moveOut == 0 ? '未迁出': data.domicile.moveOut == 1 ? '已迁出' : '确认已迁出' || ""
          that.show.idNumberTypeStr = data.userInfo.idNumberTypeStr || ""
          that.show.labelStr = data.resLabels.map(item => item.name).join(',')

          if (null != data.userInfo.headImg) {
            that.show.headImg = data.userInfo.headImg ? [{url:this.getImageStream(data.userInfo.headImg)}] : [{url:this.getImageStream("files/wx/images/content/headImg.png")}]
          }
          // 居民信息
          if (data.userRes !== null) {
            // that.orgValue = [data.userRes.province, data.userRes.city, data.userRes.county,
            //   data.userRes.street, data.userRes.community]
            that.dataForm.registryType = data.userRes.registryType + '' || ""
            that.dataForm.emMobile = data.userRes.emMobile || ""
            that.dataForm.emContact = data.userRes.emContact || ""
            that.dataForm.resRemark = data.userRes.remark || ""
            that.dataForm.orgId = data.userRes.community || ""

            that.show.registryTypeStr = data.userRes.registryTypeStr || "未知"
          }
          console.log(that.dataForm)
          //debugger
          // 居民标签
          var resLabels = data.resLabels;
          var labels = []
          if (resLabels != null) {
            let shorts = that.dataForm.shorts
            for (let i in resLabels) {
              let resLabel = resLabels[i]
              if (resLabel.shortName) {
                shorts.push(resLabel.shortName)
              }
            }
            labels = that.formatLabel(resLabels, true);
            let tabs = [
              {
                key: 'userResInfo',
                title: '居民信息',
                content: 'Content of tab 1',
              },
              {
                key: 'houseRes',
                title: '房屋信息',
                content: 'Content of tab 2',
              }]

            if (shorts.indexOf("精") > -1) {
              that.control.psychosis= true
            }

            if (shorts.indexOf("访") > -1) {
              that.control.petition= true
            }
          }
          that.labels = labels

          let idsArray = labels.map((item) => {
            item["delete"]=false
            return item.ids
          })
          that.dataForm.labels = that.dataForm.labels.concat(idsArray)
        } else {
          that.$toast.fail(data.msg)
        }
      })
    },
    getInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/info`),
        method: 'post',
        params: this.$http.adornParams({
          id: parseInt(that.dataForm.id),
          orgId: that.$globalData.userInfo.orgId,
          userType: 1
        })
      }).then(({data}) => {
        if (data.code == 0) {
          // 用户基本信息
          that.dataForm.id = data.userInfo.id || 0
          that.dataForm.name = data.userInfo.name || ""
          that.dataForm.mobile = data.userInfo.mobile || ""
          that.dataForm.sex = data.userInfo.sex || ""
          that.dataForm.nationality = data.userInfo.nationality || ""
          that.dataForm.nation = data.userInfo.nation || ""
          that.dataForm.idNumber = data.userInfo.idNumber || ""
          that.dataForm.birthday = data.userInfo.birthday || ""
          that.dataForm.regAddress = data.userInfo.regAddress || ""
          that.dataForm.nowAddress = data.userInfo.nowAddress || ""
          that.dataForm.headImg = data.userInfo.headImg || ""
          that.dataForm.company = data.userInfo.company || ""
          that.dataForm.death = data.userInfo.death || "0"
          that.dataForm.deathDate = data.userInfo.deathDate || ""
          that.show.sex = data.userInfo.sex == 1 ? '男' : '女'
          that.show.nationalityStr = data.userInfo.nationalityStr || "未知"
          that.show.nationStr = data.userInfo.nationStr || "未知"
          that.show.idNumberTypeStr = data.userInfo.idNumberTypeStr || ""
          if (null != data.userInfo.headImg) {
            that.show.headImg = data.userInfo.headImg ? [{url:this.getImageStream(data.userInfo.headImg)}] : [{url:this.getImageStream("files/wx/images/content/headImg.png")}]
          }
          // 居民信息
          if (data.userRes !== null) {
            // that.orgValue = [data.userRes.province, data.userRes.city, data.userRes.county,
            //   data.userRes.street, data.userRes.community]
            that.dataForm.registryType = data.userRes.registryType + '' || ""
            that.dataForm.emMobile = data.userRes.emMobile || ""
            that.dataForm.emContact = data.userRes.emContact || ""
            that.dataForm.resRemark = data.userRes.remark || ""
            that.dataForm.orgId = data.userRes.community || ""

            that.show.registryTypeStr = data.userRes.registryTypeStr || "未知"
          }
          console.log(that.dataForm)
          //debugger
          // 居民标签
          var resLabels = data.resLabels;
          var labels = []
          if (resLabels != null) {
            let shorts = that.dataForm.shorts
            for (let i in resLabels) {
              let resLabel = resLabels[i]
              if (resLabel.shortName) {
                shorts.push(resLabel.shortName)
              }
            }
            labels = that.formatLabel(resLabels, true);
            let tabs = [
              {
                key: 'userResInfo',
                title: '居民信息',
                content: 'Content of tab 1',
              },
              {
                key: 'houseRes',
                title: '房屋信息',
                content: 'Content of tab 2',
              }]

            if (shorts.indexOf("精") > -1) {
              that.control.psychosis= true
            }

            if (shorts.indexOf("访") > -1) {
              that.control.petition= true
            }
          }
          that.labels = labels

          let idsArray = labels.map((item) => {
            item["delete"]=false
            return item.ids
          })
          that.dataForm.labels = that.dataForm.labels.concat(idsArray)
        }
      })
    },
    userSelect() {
      //户籍性质
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data.code == 0) {
          data.registryTypes.map((type) => {
            type.value = type.value + ""
          })
          that.selectList.registryTypeList = data.registryTypes
        }
      })

      //居民标签
      listComRightLabel(function (labels) {
        that.selectList.userCommunityLabelTree = labels
      })

      //身份证类型
      getVirtualDict("idNumberType", function (virtualDictList) {
        that.selectList.idNumberTypeList = virtualDictList
      })
    },

    headImgButton(e) {
      if (!that.control.disabled) {
        let path = "files/userHeadImg/temporary"
        upload(e.file, path, null, function (res) {
          // alert(JSON.stringify(res))
          that.show.headImg = [{url: getImageStream(res.fileMessage.relativePath)}]
          that.dataForm.temporaryHeadImg = res.fileMessage.relativePath
          that.dataForm.temporaryHeadImgSuffix = res.fileMessage.suffix
        })
      }
    },
    goNation() {
      if(!this.control.disabled) {
        this.$router.push('/nation')
      }
    },
    goNationality() {
      if(!this.control.disabled){
        this.$router.push('/nationality')
      }
    },
    getImageStream(e) {
      return getImageStream(e)
    },
    showIdNumberType() {
      if (!this.control.disabled) {
        this.control.idNumberType = true
      }
    },
    showDeath() {
      if (!this.control.disabled) {
        this.control.death = true
      }
    },
    showSex() {
      if (!this.control.disabled) {
        this.control.sex = true
      }
    },
    idNumberType(e) {
      this.dataForm.idNumberType = parseInt(e.value)
      this.show.idNumberTypeStr = e.label
      this.control.idNumberType = false
    },
    showBirthday() {
      if (!this.control.disabled) {
        this.control.birthday = true
      }
    },

    birthdayCalendar(e) {
      let date = formatterDate(e)
      this.control.birthday = false
      this.dataForm.birthday = date

    },

    showRegistryType() {
      if (!this.control.disabled) {
        this.control.registryType = true
      }
    },
    choseDeath (e) {
      this.dataForm.death = parseInt(e.value)
      this.show.death = e.label
      this.control.death = false
    },
    choseSex (e) {
      this.dataForm.sex = parseInt(e.value)
      this.show.sex = e.label
      this.control.sex = false
    },
    registryType(e){
      this.dataForm.registryType = parseInt(e.value)
      this.show.registryTypeStr = e.label
      this.control.registryType = false
    },
    showDeathDate() {
      if (!this.control.disabled) {
        this.control.deathDate = true
      }
    },
    showlabel() {
      // //debugger
      if (!this.control.disabled) {
        this.control.label = true
      }
    },
    deathDateCalendar(e) {
      let date = formatterDate(e)
      this.control.deathDate = false
      this.dataForm.deathDate = date
    },
    //标签级联的关闭
    labelOverlay() {
      // this.setData({ 'control.communityLabelCascader= false })
      let temporaryLabel = that.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }

      temporaryLabel["delete"] = false

      that.labels.push(temporaryLabel)
      that.dataForm.labels.push(temporaryLabel.ids)
    },
    //标签级联重置
    labelClose(e) {
    },
    labelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = this.formatLabel(map, false)
      that.temporaryLabel = temporaryLabel
    },
    //删除标签
    removeLabel(value) {
      for (let i in that.dataForm.labels) {
        let label = that.dataForm.labels[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          that.dataForm.labels.splice(i, 1)
        }
      }

      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == value) {
          that.labels[i]["delete"] = true
        }
      }
      that.dataForm.labels = that.dataForm.labels
      that.labels = that.labels
      this.$forceUpdate()
    },
    /**
     * 处理标签显示效果\n
     *
     * @param resLabels
     * @param isOld 是否后端传入的旧数据
     * @returns {[]}
     */
    formatLabel: function (resLabels, isOld) {
      var labels = [];
      if (isOld) {
        for (var i = 0; i < resLabels.length; i++) {
          //是否存在第二级
          var exists2 = false;
          if (resLabels[i].parentId == 0) {
            for (var j = 0; j < resLabels.length; j++) {
              if (resLabels[j].parentId == resLabels[i].id) {
                exists2 = true;
                //是否存在第三级标签
                var exists3 = false;
                for (var k = 0; k < resLabels.length; k++) {
                  if (resLabels[k].parentId == resLabels[j].id) {
                    exists3 = true;
                    labels.push({
                      value: resLabels[k].id,
                      label: resLabels[k].name,
                      name: resLabels[i].name + "：" + resLabels[j].name + "(" + resLabels[k].name + ")",
                      ids: resLabels[i].id + "," + resLabels[j].id + "," + resLabels[k].id,
                      rightShow: resLabels[k].rightShow,
                      rightUpdate: resLabels[k].rightUpdate
                    })
                  }
                }
                if (!exists3) {
                  //不存在第三级，则加入第二级
                  labels.push({
                    value: resLabels[j].id,
                    label: resLabels[j].name,
                    name: resLabels[i].name + "：" + resLabels[j].name,
                    ids: resLabels[i].id + "," + resLabels[j].id,
                    rightShow: resLabels[j].rightShow,
                    rightUpdate: resLabels[j].rightUpdate
                  })
                }
              }
            }
            if (!exists2) {
              //不存在第二级，则加入第一级
              labels.push({
                value: resLabels[i].id,
                label: resLabels[i].name,
                name: resLabels[i].name,
                ids: resLabels[i].id,
                rightShow: resLabels[i].rightShow,
                rightUpdate: resLabels[i].rightUpdate
              })
            }
          }
        }
      } else {
        let ids = resLabels.value
        let options = resLabels.options;
        if (ids.length == 1) {
          let option = options[0]
          //只有第一级
          labels.push({
            value: option.value,
            label: option.label,
            name: option.label,
            ids: option.value,
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 2) {
          let option1 = options[0]
          let option2 = options[1]
          //不存在第三级，则加入第二级
          labels.push({
            value: option2.value,
            label: option2.label,
            name: option1.label + "：" + option2.label,
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 3) {
          let option1 = options[0]
          let option2 = options[1]
          let option3 = options[2]
          //不存在第三级，则加入第二级
          labels.push({
            value: option3.value,
            label: option3.label,
            name: option1.label + "：" + option2.label + "(" + option3.label + ")",
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        }
      }
      return labels;
    },
    //提交
    onSubmit(e) {
      //校验身份证号
      this.$dialog.confirm({
        message: '确认提交？',
      }).then(() => {
        // //debugger
        let check = true
        if (that.dataForm.idNumberType == 1 && check) {
          let numberBool = identityCodeValid(that.dataForm.idNumber)
          if (numberBool == '身份证号格式错误') {
            that.$toast.fail(numberBool)
            check = false
          }
        }
        if (check) {
          this.$http({
            url: this.$http.adornUrl(`/wxapp/user/save`),
            method: 'post',
            data: this.$http.adornData({
              'id': that.dataForm.id || undefined,
              'name': that.dataForm.name,
              'mobile': that.dataForm.mobile,
              'sex': that.dataForm.sex,
              'nationality': that.dataForm.nationality,
              'nation': that.dataForm.nation,
              'idNumberType': that.dataForm.idNumberType,
              'idNumber': that.dataForm.idNumber,
              'birthday': that.dataForm.birthday,
              'regAddress': that.dataForm.regAddress,
              'nowAddress': that.dataForm.nowAddress,
              'headImg': that.dataForm.headImg,
              'company': that.dataForm.company,
              'registryType': that.dataForm.registryType,
              'labels': that.dataForm.labels.toString(),
              'death': parseInt(that.dataForm.death),
              'deathDate': that.dataForm.deathDate,
              'resRemark': that.dataForm.resRemark,
              'emMobile': that.dataForm.emMobile,
              'emContact': that.dataForm.emContact,
              'orgId': that.dataForm.orgId,
              'temporaryHeadImg': that.dataForm.temporaryHeadImg,
              'temporaryHeadImgSuffix': that.dataForm.temporaryHeadImgSuffix
            })
          }).then(({data}) => {
            if (data.code == 0) {
              that.uploadHouse(data.userId)
            } else {
              that.$toast.fail(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.border {
  width: 100%;
  height: 20px;
  background-color: #f5f5f5;
}
.content {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-top: -0.3rem;
  margin-bottom: -0.3rem;
  background-color: #f5f5f5;
}

.head-img-button {
  margin-top: 20px;
  color: #b3b3b3;
  border-color: #b3b3b3;
  width: 200px;
}

.vanTag {
  margin-left: 3px;
}
::v-deep .van-uploader__preview-image {
  border-radius: 50%;
}
</style>
